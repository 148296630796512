import React, {useState,useEffect} from 'react';
import sanityClient from './Backend';
import SpeedLottie from './components/speedLottie.jsx';
import SuccessLottie from './components/successLottie.jsx';
import img1 from './images/transferir (1).png';
import img2 from './images/002-anger.svg';
import img3 from './images/016-suffering.svg';
import img4 from './images/036-depressed.svg';
import img5 from './images/034-tired.svg';
import img6 from './images/045-puzzled.svg';
import img7 from './images/023-lonely.svg';
import img8 from './images/038-lost.svg';
import img9 from './images/043-scared.svg';
import img10 from './images/020-pensive.svg';
import higor from './images/e26b8908-4462-477e-b03d-47fb8e753d00.jpg'
export default function MatriculaExpress() {
 
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)

 
 
         useEffect(() => {   

            const script = document.createElement("script");   
            const script2 = document.createElement("script");     
            const formDiv= document.createElement("div");
            const formDiv2= document.createElement("div"); 
            
            formDiv.className ="_form_5"; 
            formDiv2.className ="_form_5";
    
            script.src ="https://higorfabiano.activehosted.com/f/embed.php?id=5";       
            script2.src ="https://higorfabiano.activehosted.com/f/embed.php?id=5";
            
            document.getElementById("form").appendChild(formDiv);                     
            document.getElementsByClassName("script1")[0].appendChild(script);     
      
            document.getElementById("form2").appendChild(formDiv2);  
            document.getElementsByClassName("script2")[0].appendChild(script2);      
          
            

                    
            sanityClient.fetch(
                `*[_type == "universalPageData" && dataType== 'image' && title == 'TestImage2' ]{
                     image{
                      asset ->{          
                      url
                    }
                    },
                    }`
            ).then(d => {
                setImage1(d[0].image.asset.url)
                setImage2(d[0].image.asset.url)})       
    
            return () => {
                <div> {/* ... */} </div>              
            }
        }, [])   

        

    return( 
    <div className="main flex flex-col items-center justify-center ">
        <div id="vantablack" className="herodiv min-w-screen w-full min-h-screen flex items-center justify-center pb-8 pt-8 lg:pb-0 lg:pt-0">
        <div className="bg-white w-5/6 min-h-5/6 rounded-3xl p-8 max-w-screen-xl">
        <div className="leftright flex lg:flex-row flex-col items-center justify-center">
            <div className="left lg:w-1/2">
                <h1 className="custom-text-font text-black text-6xl text-center md:text-left font-sans font-bold text-opacity-90 mb-6 md:text-8xl lg:pl-5">Matrícula Express</h1>
                <div className="leftright flex lg:flex-row flex-col ">
                    <div className="left flex items-center md:justify-start justify-center mr-4">
                        <div className="rounded-full orange w-24 h-24" >
                            <div className="to-become-white">
                                <SpeedLottie />
                                </div>
                            
                        </div>
                    </div>
                    <div className="right p-2">
                        <p className="text-opacity-90 text-black font-sans text-base mb-2">De 9 a 12 de Maio às 20h21min (horário de Brasília)</p>
                        <p className="text-opacity-90 text-black font-sans text-2xl">
                        <strong>Aprenda em 4 dias</strong> o que você não aprendeu em anos.
                        <div className="wrapperdiv1">
                <p className="text-opacity-90 text-black font-sans text-2xl pt-4 pb-4">Descubra o segredo por trás de quem já fez mais de <strong>10 mil matrículas</strong> e fundou a primeira escola de Matrículas do Brasil.</p>
            </div>



</p></div>
                </div>
            </div>
            <div className="right lg:w-1/2 h-100 flex flex-col lg:items-end items-center"> 
            <img className="h-30 w-20  mr-2 mb-2" src={img1}/>   
            <div className="text-opacity-90 text-black font-sans text-2xl pl-5"><p>Preencha os campos abaixo e garanta a sua presença no Curso
<strong className="text-4xl"> Gratuito e Online </strong> Matrícula Express:</p></div>   
            <div id="form" className="form w-full"></div> 
            <div className="script1"></div>           
            </div>
            
        </div>

        </div>  
        </div>
 <div className="lg:p-8 lg:max-w-screen-lg lg:w-full lg:p-8">
<h2 className="text-opacity-90 text-black font-sans text-center lg:text-left lg:p-0 p-6 lg:text-4xl text-4xl font-bold mb-6 mt-24">Para quem é destinado esse curso gratuito?</h2>
<p className="text-opacity-90 text-black font-sans text-2xl lg:p-0 p-6 text-center lg:text-left">Para <strong>Consultores, Gerentes, Diretores e Donos de Cursos de todos os cursos Livres</strong>, podendo ser:<strong> Idiomas, Profissionalizantes, Técnicos e também Graduações, Pós e MBA.</strong></p>
<h2 className="text-opacity-90 text-black font-sans text-center lg:text-left lg:p-0 p-6 lg:text-4xl text-4xl  font-bold mb-6 mt-6">Para quem NÃO É destinado esse curso Gratuito?</h2>
<p className="text-opacity-90 text-black font-sans text-2xl lg:p-0 p-6 mb-24 text-center lg:text-left ">Para Escola Regular (educação infantil, fundamental, médio), afiliados, infoprodutores.</p>


<div className="opacity-90 bg-black text-white p-6 lg:p-4">
        <div className="">
        <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-6 lg:text-5xl text-4xl font-bold mb-12 mt-6">Se você está cansado de:</h2>
        <div className="flex flex-col md:flex-row mb-12">
                 <div className="flex items-center justify-start">
                          <div className="left3column">
                             <img className="min-h-30 min-w-20   mr-2 mb-2" src={img9}/>  
                         </div>
                         
                         <div className="right3column">
                             <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Ouvir: ‍“Volto amanhã para me matricular”</p>

                         </div>
                  </div>
                  <div className="flex items-center justify-start">
                          <div className="left3column">
                             <img className="min-h-30 min-w-20  mr-2 mb-2" src={img6}/>  
                         </div>
                         
                         <div className="right3column">
                         <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Não matricular logo no primeiro atendimento</p>
                         </div> 
                   </div>
                  <div className="flex items-center justify-start">
                         <div className="left3column">
                             <img className="min-h-30 min-w-20  mr-2 mb-2" src={img5}/>  
                         </div>
                         
                         <div className="right3column">
                         <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Tomar bolo e perder seu tempo</p>
                         </div> 
                  </div>
        </div>
        </div>
        <div className="">
        <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-6 lg:text-5xl text-4xl font-bold mb-12 mt-6">Se você odeia:</h2>
        <div className="flex flex-col md:flex-row mb-12">
                 <div className="flex items-center justify-start">
                          <div className="left2columns">
                             <img className=" min-h-30 min-w-20  mr-2 mb-2 p-1" src={img3}/>  
                         </div>
                         
                         <div className="right2columns">
                             <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left lg:mr-4">Receber objeções atrás de objeções</p>

                         </div>
                  </div>
                  <div className="flex items-center justify-start">
                          <div className="left2columns">
                             <img className=" min-h-30 min-w-20 mr-2 mb-2 p-1" src={img2}/>  
                         </div>
                         
                         <div className="right2columns">
                         <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Ligar para clientes antigos</p>
                         </div> 
                         
                                            </div>
           
        </div>
        </div>
        <div className="">
        <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Se você já não suporta mais:</h2>
        <div className="flex flex-col md:flex-row mb-12">
                 <div className="flex items-center justify-start ">
                          <div className="left3column">
                             <img className="min-h-30 min-w-20  mr-2 mb-2" src={img10}/>  
                         </div>
                         
                         <div className="right3column">
                             <p className="text-opacity-90 font-sans text-xl font-semibold lg:p-0 p-6 mb-6 text-left ml-4">Cliente desligando na sua cara”</p>

                         </div>
                  </div>
                  <div className="flex items-center justify-start">
                          <div className="left3column">
                             <img className="min-h-30 min-w-20  mr-2 mb-2" src={img7}/>  
                         </div>
                         
                         <div className="right3column">
                         <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Não bater suas metas por não ter leads</p>
                         </div> 
                   </div>
                  <div className="flex items-center justify-start">
                         <div className="left3column">
                             <img className="min-h-30 min-w-20  mr-2 mb-2 " src={img4}/>  
                         </div>
                         
                         <div className="right3column">
                         <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Trabalhar finais de semanas e feriados</p>
                         </div> 
                  </div>
                 
        </div>
        <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Então...</h2>
        </div>



</div>

</div>



<div className="lg:p-8 lg:p-8 lg:max-w-screen-lg lg:w-full">
    <h2 className="opacity-90 font-sans text-center lg:text-center lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Matrícula Express é pra você!</h2>
    <div className="lottieok mb-6"> <SuccessLottie /></div>
    <p className="lg:-ml-5 lg:mr-5 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">Serão 4 dias de curso totalmente gratuito e online com os seguintes temas:</p>
    <p className="lg:-ml-3 lg:mr-3 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">09/05:
Agendamento Implacável: Descubra os erros que todo matriculador comete no agendamento e que você nunca mais vai cometer, e domine as técnicas para eliminar os bolos.</p>
<p className="lg:-ml-2 lg:mr-2 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">‍10/05:
Leads infinitos: Aprenda a estratégia para criar Leads infinitos no atendimento e nunca mais dependa de marketing, ação externa ou banco de dados.</p>
<p className="lg:-ml-1 lg:mr1 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">11/05:
Fechamento de Sucesso: Os passos para um Fechamento no primeiro atendimento.</p>
<p className="lg:-ml-0 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">12/05:
Análise de Script ao Vivo: Tenha seu script avaliado e elimine todos os bolos da sua vida.</p>
</div>

<div id="form2" className="formbox border-solid border-2 border-gray-200 p-6 m-8 max-w-screen-lg">
        <div className="leftright flex flex-row">
             <div className="left w-5/6">
             <p className="text-opacity-90 text-black font-sans text-2xl pl-5 mb-6">Preencha os campos abaixo e garanta a sua presença no Curso
<strong className="text-4xl"> Gratuito e Online </strong> Matrícula Express:</p>
             </div>
             <div className="right 1/6">
             <img className="h-30 w-20  mr-2 mb-2" src={img1}/>
             </div>
        </div>


<div id="form2" clasName="form w-full"> </div>
<div className="script2"></div>

</div>


<div>
<div className="title-who-we-are mt-12 mb-24 p-4">

<h2 className="opacity-90 font-sans text-center lg:text-left lg:p-0 p-6 lg:text-5xl text-4xl font-bold mb-12 mt-6">Quem Somos</h2>

<div className="higor-image-plus-text-wrapper flex lg:flex-row flex-col items-center justify-center max-w-screen-lg">

    <div className="higorimage w-60 orange flex flex-col items-center justify-center rounded-2xl p-2 mr-6 lg:mb-0 mb-6">
      <img className="w-60 h-auto rounded-2xl" src={higor} alt="Higor Fabiano"/> 
        <div className="pwrwapper w-full">
        <p className="text-white text-right font-sans text-xl font-thin pt-2">Higor Fabiano</p>
        </div>
        <div className="pwrwapper w-full">
        <p className="text-white text-right font-sans text-xl font-thin pt-2">O Matriculador</p>
        </div>        
        </div>
    <div className="who-we-are-text lg:w-3/4 orange text-white rounded-xl p-6 font-sans text-lg">
        <p className="mb-6">E ai!!! Eu sou Higor de Almeida Fabiano e eu também fico 1 hora ouvindo lamentações, alegrias, sendo um psicólogo/amigo e as vezes consultor. E foi assim que entendi o poder que tinha nas mãos: Transformar a vida das pessoas, e a minha, por meio da Educação.
Foi no dia que entendi isso que decidi largar o Direito e me profissionalizar, me tornando um <strong>"Matriculador"</strong>
‍</p>

<p>
Sempre digo que cada matrícula que faço é um tijolinho a mais no meu objetivo. Se não fossem as matrículas eu não estaria onde estou hoje.
Se você está aqui é porque também sabe o valor da matrícula, tanto para o próximo quanto para você</p>

    </div>

</div>

</div>
</div>


            
               

         {/*End of component*/}
         </div>

        
           
    
    )
} 

