import logo from './logo.svg';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.css';
import Home from './Home';
import MatriculaExpress from './MatriculaExpress';
import Obrigado from './Obrigado';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Switch>
       <Route component={Home} path='/' exact />      
       <Route component={MatriculaExpress} path='/matriculaexpress' />
       <Route component={Obrigado} path='/obrigado' />
     </Switch>
     </BrowserRouter>
    </div>
  );
}

export default App;
