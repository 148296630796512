import React, { Component } from 'react'
import Lottie from 'react-lottie'
import animationData from '../lotties/12554-error.json'

class ErrorLottie extends Component {

  render(){

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div>     
        <Lottie options={defaultOptions}
              height={180}
              width={180}
        />
      </div>
    )
  }
}

export default ErrorLottie 
