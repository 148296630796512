import React from 'react';
import ErrorLottie from './components/errorLottie.jsx';
import whatsApp from './images/124034.png';
import lock from './images/lock.svg';
import letsEncript from './images/le-logo-twitter-noalpha-1-p-500.png'; 



export default function Obrigado() {

    return (<div >
        <div className="obrigado-body p-6">
        <ErrorLottie />
        <h2 className="obrigado-texts opacity-90 font-sans text-8xl font-bold p-6 mb-0 mt-4 text-center">QUASE</h2>
        <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga no Curso Gratuito Matrícula Express!</p>
        <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Se programe pois as aulas <strong className="text-red-500">NÃO TERÃO REPLAY</strong></p>
        <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Por esse motivo criei um:</p>
        <div onClick={(e) => (window.location = 'https://tiny.cc/matlink?email=false')} className="bgsupergreen cursor-pointer h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex items-center justify-center p-4 mt-8 mb-8" > 
              <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-3xl font-bold p-6 mb-0 text-center">GRUPO DE ALUNOS NO WHATSAPP</p></div> 
              <div className="flex items-center justify-start min-w-20"><img className="h-20 w-20 object-scale-down" src={whatsApp} alt="WhatsApp Icon"/></div>

               </div>
               <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Para te enviar materiais complementares, esclarecer dúvidas das aulas e te lembrar sempre que ela começar.</p>       
               <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Para entrar no grupo é só clicar no botão abaixo!</p>       
               <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Obs: grupo ficará fechado para não te atrapalhar e será aberto somente para tirar as dúvidas.</p>       
               <div onClick={(e) => (window.location = 'https://tiny.cc/matlink?email=false')} className="bg-yellow-600 cursor-pointer h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex items-center justify-center p-4  mt-8 mb-8" > 
              <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-3xl font-bold p-6 mb-0 text-center">Quero Entrar no Grupo de Alunos!</p></div> 
               </div>

               

               </div>
               <div className="bg-white opacity-75 min-h-30 w-full flex flex-row items-center justify-center p-6">
                   <div className="h-12 w-12 object-scale-down"><img src={lock} alt=""/></div>
                   <div className="ml-6 mr-6 font-sans text-2xl font-bold text-center"><p>Suas Informações Estão Seguras</p></div>
                   <div className="h-20 w-20 object-scale-down"><img src={letsEncript} alt=""/></div>
               </div>
        </div>
)
}

